import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import Chatbot from "../../../../../Chatbot";
import GymWorkoutDisplay from "../GymWorkoutDisplay";
import ManagedList from "../../../../../components/list/ManagedList";
import AssociationsDropdown from "../../../../../components/AssociationsDropdown";
import { useSystemCollection } from "../../../../../context/systemColections";
import useSourceAttributes from "../../../../../context/useSourceAttributes";

const PersonalTrainer = () => {
    const [completionId, setCompletionId] = useState(null);
    const [selectedAssociation, setSelectedAssociation] = useState(null);
    const [listKey, setListKey] = useState(0);
    const [gymLogReportId, setGymLogReportId] = useState(null);
    const [userFitnessGoal, setUserFitnessGoal] = useState('');

    const { getSystemCollectionIdByName, isLoading: systemCollectionLoading, error: systemCollectionError } = useSystemCollection();
    const { getAllAttributes, loading: attributesLoading, error: attributesError } = useSourceAttributes();

    useEffect(() => {
        const fetchGymLogReportId = async () => {
            try {
                const id = await getSystemCollectionIdByName("Gym Log Report");
                setGymLogReportId(id);
            } catch (err) {
                console.error("Error fetching Gym Log Report ID:", err);
            }
        };

        fetchGymLogReportId();
    }, [getSystemCollectionIdByName]);

    useEffect(() => {
        if (!selectedAssociation?.id) return;

        const fetchGoal = async () => {
            try {
                const attributes = await getAllAttributes(selectedAssociation.id);
                const goalAttribute = attributes.find(attr => attr.name === 'current_gym_goal');
                setUserFitnessGoal(goalAttribute?.value || '');
            } catch (err) {
                console.error("Error fetching goal:", err);
                setUserFitnessGoal('');
            }
        };

        fetchGoal();
    }, [selectedAssociation?.id]);

    const handleAssociationSelect = useCallback((selectedCollection) => {
        setSelectedAssociation(selectedCollection);
        setCompletionId(null);
    }, []);

    const handleSourceDelete = useCallback(() => {
        setListKey(prevKey => prevKey + 1);
    }, []);

    const chatConfig = useMemo(() => {
        if (!selectedAssociation || !gymLogReportId) return null;

        return {
            systemPromptPrefix: "You are a fitness coach assistant. Your primary role is to listen and record the user's workout activities and sets, but also give advice but ONLY when asked to. Don't ask questions, like \"how many do you plan to do\", let the user tell you. You are a scribe asking what they have done, not what they plan." +
                "Example will be a user saying on different responses: Bench Press, 110 lbs. ... 8 ... 8 ... 8 Incline flys, 25lbs 8 ... 8 ... 8 ..., record as they go, minimum response unless specifically asked." +
                "The final report should look like:" +
                "{\n" +
                "  \"goal\": \"Improve upper body strength and core stability\",\n" +
                "  \"activities\": [\n" +
                "    {\n" +
                "      \"activity\": \"Standing curls\",\n" +
                "      \"sets\": [\n" +
                "        {\"weight\": 35, \"reps\": 8},\n" +
                "        {\"weight\": 35, \"reps\": 8},\n" +
                "        {\"weight\": 35, \"reps\": 8}\n" +
                "      ],\n" +
                "      \"assessment\": \"easy\",\n" +
                "      \"notes\": \"Increase weight next time\"\n" +
                "    },\n" +
                "    {\n" +
                "      \"activity\": \"Crunch machine\",\n" +
                "      \"sets\": [\n" +
                "        {\"weight\": 100, \"reps\": 8},\n" +
                "        {\"weight\": 100, \"reps\": 8},\n" +
                "        {\"weight\": 100, \"reps\": 8}\n" +
                "      ],\n" +
                "      \"assessment\": \"challenging\",\n" +
                "      \"notes\": \"Repeat weight next time\"\n" +
                "    }\n" +
                "  ],\n" +
                "  \"workoutNotes\": \"Did well on curls but struggled with crunch machine.\"\n" +
                "}",
            goal: "Your goal is to accurately record the user's workout information and provide support and guidance ONLY when asked.",
            specialInstructions: [
                "For each exercise, report back what is recorded for that exercise. DON'T ASK WHAT THEY PLAN TO DO, ONLY THAT THEY RECORD WHAT THEY HAVE DONE. DON'T ASK ABOUT HOW MANY THEY PLAN TO DO!!!"
            ],
            initialContext: [
                { "name": "Exercise_Log", "contentType": "application/source", "content": gymLogReportId },
                { "name": "Users_Overall_Fitness_Goal", "contentType": "text/plain", "content": userFitnessGoal },
                { "name": "todays_date", "contentType": "text/plain", "content": new Date().toISOString().slice(0, 10).replace(/-/g, "/") }
            ],
            requiredInformation: [
                ["activity", "The name of each exercise or activity being performed."],
                ["sets", "The number of sets performed for each activity."],
                ["reps", "The number of repetitions for each set, if applicable."],
                ["weight", "The weight used for each set, if applicable."],
                ["duration", "The duration of the activity, if applicable (e.g., for cardio exercises)."]
            ],
            optionalInformation: [
                ["resistance", "The resistance level for applicable exercises (e.g., elliptical)."],
                ["incline", "The incline level for applicable exercises (e.g., treadmill, elliptical)."],
                ["assessment", "User's assessment of the activity (e.g., easy, hard, too much, not enough)."],
                ["notes", "User provided notes for the activity (e.g., increase weight next time, don't do this activity again, etc..."]
            ],
            initialGreeting: "Have a natural conversation with me as a chef to collect the following information.  The user will be recording activity and then reps. Don't be so wordy and mostly listen and record. Start off with message: It's a great day for a workout!  What are your goals for today?",
            closingMessage: "Great job on your workout! Here's a summary of what you've accomplished today.",
            confirmResultsWithUser: true,
            completionCallbackName: "workoutCompletionCallback",
            completionCallbackConfig: {
                "toAssociations": [selectedAssociation.id]
            },
            associations: [selectedAssociation.id]
        };
    }, [selectedAssociation, gymLogReportId, userFitnessGoal]);

    const handleConversationComplete = useCallback((data) => {
        if (data.completionResult && data.completionResult.result) {
            setCompletionId(data.completionResult.result);
        }
    }, []);

    if (systemCollectionLoading || attributesLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (systemCollectionError || attributesError) {
        return <Typography color="error">Error: {systemCollectionError?.message || attributesError?.message}</Typography>;
    }

    return (
        <Container maxWidth="md">
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 4 }}>
                Your Personal Fitness Coach
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%', maxWidth: 600, mb: 4 }}>
                    <AssociationsDropdown
                        rootAssociation="Gym Workouts"
                        selected={selectedAssociation?.label || 'Gym Workouts'}
                        onCollectionSelect={handleAssociationSelect}
                        selectionLabel="Select a workout collection"
                    />
                </Box>
                <Box sx={{ width: '100%', maxWidth: 600 }}>
                    {selectedAssociation && chatConfig && !attributesLoading && (
                        <Chatbot
                            agentName="Fitness Coach"
                            initialConfig={chatConfig}
                            onConversationComplete={handleConversationComplete}
                            sourceId={selectedAssociation.id}
                        />
                    )}
                </Box>
            </Box>
            {completionId && (
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>
                        Your Workout Summary
                    </Typography>
                    <ManagedList
                        key={listKey}
                        query={`source/search?includeContent=true&ids=${completionId}`}
                        ItemDisplay={GymWorkoutDisplay}
                        showAssociationsDropdown={false}
                        showFilter={false}
                        loadOnDefault={true}
                        onSourceDelete={handleSourceDelete}
                    />
                </Box>
            )}
        </Container>
    );
};

export default PersonalTrainer;