import React, { useMemo } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MuscleGroupAnalysis = ({ workouts }) => {
    const theme = useTheme();

    // Predefined array of colors
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1'];

    const muscleGroupData = useMemo(() => {
        const muscleGroups = {};
        workouts.forEach(workout => {
            if (workout.content?.content && Array.isArray(workout.content.content.activities)) {
                workout.content.content.activities.forEach(activity => {
                    const primary = activity.primary;
                    if (primary && primary !== '') {
                        if (!muscleGroups[primary]) {
                            muscleGroups[primary] = { count: 0, totalVolume: 0, exercises: new Set(), lastWorkout: null };
                        }
                        muscleGroups[primary].count += 1;
                        muscleGroups[primary].exercises.add(activity.activity);
                        muscleGroups[primary].lastWorkout = workout.date;

                        const volume = activity.sets.reduce((total, set) => {
                            const weight = parseFloat(set.weight) || 0;
                            const reps = parseInt(set.reps) || 0;
                            return total + (weight * reps);
                        }, 0);
                        muscleGroups[primary].totalVolume += volume;
                    }
                });
            }
        });

        return Object.entries(muscleGroups).map(([name, data]) => ({
            name,
            count: data.count,
            averageVolume: data.totalVolume / data.count,
            exercises: Array.from(data.exercises),
            lastWorkout: data.lastWorkout
        }));
    }, [workouts]);

    const pieChartData = muscleGroupData.map(({ name, count }) => ({ name, value: count }));

    // Filter out 'Cardio' from average volume data
    const volumeChartData = muscleGroupData.filter(group => group.name.toLowerCase() !== 'cardio');

    return (
        <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom color="primary">
                Muscle Group Analysis
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                        <Typography variant="h6" gutterBottom color="secondary">
                            Muscle Group Focus
                        </Typography>
                        <Typography variant="h7" gutterBottom color="info">
                            (By Exercise Count)
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={pieChartData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                    outerRadius={80}
                                    fill={theme.palette.primary.main}
                                    dataKey="value"
                                >
                                    {pieChartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                        <Typography variant="h6" gutterBottom color="secondary">
                            Average Volume per Exercise
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={volumeChartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="averageVolume" fill={theme.palette.primary.main} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom color="secondary">
                            Muscle Group Details
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Muscle Group</TableCell>
                                        <TableCell align="right">Workout Count</TableCell>
                                        <TableCell align="right">Average Volume</TableCell>
                                        <TableCell>Exercises</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {muscleGroupData.map((group) => (
                                        <TableRow key={group.name}>
                                            <TableCell component="th" scope="row">
                                                {group.name}
                                            </TableCell>
                                            <TableCell align="right">{group.count}</TableCell>
                                            <TableCell align="right">
                                                {group.name.toLowerCase() === 'cardio' ? 'N/A' : group.averageVolume.toFixed(2)}
                                            </TableCell>
                                            <TableCell>{group.exercises.join(", ")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MuscleGroupAnalysis;