import { Container, Typography} from '@mui/material';
import MealDesigner from "./MealDesigner";

const MealTracker = () => {

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Meal Tracker</Typography>
            <MealDesigner/>
        </Container>
    );
};

export default MealTracker;
