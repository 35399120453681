import React, { useState, useEffect, useCallback } from 'react';
import ManagedPage from "../../../../components/apps/ManagedPage";
import CatalogStats from "./CatalogStats";

function Catalog() {
    const [query, setQuery] = useState('/source/search?types=Source');
    const [rootAssociation, setRootAssociation] = useState('AIssistant');
    const [expanded, setExpanded] = useState(false);
    const [loadOnDefault, setLoadOnDefault] = useState(false);

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash.startsWith('#')) {
                const id = hash.slice(1).trim();
                if (id) {
                    setQuery(`/source/by-ids?ids=${id}`);
                    setRootAssociation('X');
                    setExpanded(true);
                    setLoadOnDefault(true);
                } else {
                    resetToDefault();
                }
            } else {
                resetToDefault();
            }
        };

        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    const resetToDefault = useCallback(() => {
        setQuery('/source/search?');
        setRootAssociation('AIssistant');
        setExpanded(false);
        setLoadOnDefault(false);
    }, []);

    return (
        <ManagedPage
            title="Catalog"
            systemCollectionName="AIssistant"
            sourceType="Source"
            showExtractor={false}
            StatsComponent={({ items }) => <CatalogStats items={items} />}
            eagerLoad={false}
            managedListProps={{
                query: query,
                rootAssociation: rootAssociation,
                showAssociationsDropdown: false,
                showFilter: true,
                autoLoadExpandedContent: true,
                expanded: expanded,
                loadOnDefault: loadOnDefault,
                showTypeSelection: true,
                defaultSelectedTypes: ["Source"],
                allowDelete: true,
                showCheckAllClear: true,
                asChecklist: true,
            }}
        />
    );
}

export default Catalog;