// src/root/Account.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import PasswordUpdateForm from "../components/PasswordUpdateForm";
import { useAuth } from "../AuthContext";

function Account() {
    const { user } = useAuth();

    return (
        <Box>
            <Typography variant="h4" gutterBottom>Account Settings</Typography>
            {user && (
                <Typography variant="h6" gutterBottom>
                    Username: {user.username}
                </Typography>
            )}
            <PasswordUpdateForm />
        </Box>
    );
}

export default Account;