import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import useSourceAttributes from "../context/useSourceAttributes";

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

const AttributeEditor = ({
                             sourceId,
                             attributeName,
                             title = "Edit Attribute",
                             placeholder = "Enter value...",
                             onValueChange,
                             minRows = 4,
                             maxRows = 8,
                             disabled = false,
                             multiline = true,
                             helperText,
                             className = "",
                             debounceMs = 500,
                             elevation = 1,
                             variant = "outlined",
                         }) => {
    const [value, setValue] = useState("");
    const [initialLoadDone, setInitialLoadDone] = useState(false);
    const debouncedValue = useDebounce(value, debounceMs);
    const theme = useTheme();
    const { upsertAttribute, getAllAttributes } = useSourceAttributes();

    // Load initial value once and mark as done
    useEffect(() => {
        if (!initialLoadDone && sourceId && attributeName) {
            getAllAttributes(sourceId).then(attributes => {
                const existingAttribute = attributes.find(attr => attr.name === attributeName);
                if (existingAttribute) {
                    setValue(existingAttribute.value || "");
                }
                setInitialLoadDone(true);
            });
        }
    }, [sourceId, attributeName, initialLoadDone]);

    // Post updates whenever debounced value changes AND initial load is done
    useEffect(() => {
        if (debouncedValue !== undefined && sourceId && attributeName && initialLoadDone) {
            upsertAttribute(sourceId, {
                name: attributeName,
                value: debouncedValue
            }).then(() => {
                onValueChange?.(debouncedValue);
            });
        }
    }, [debouncedValue, sourceId, attributeName, initialLoadDone]);

    return (
        <Card className={className} variant={variant} elevation={elevation}>
            <CardHeader title={<Typography variant="h6">{title}</Typography>} />
            <CardContent>
                <TextField
                    fullWidth
                    multiline={multiline}
                    minRows={minRows}
                    maxRows={maxRows}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={disabled}
                    helperText={helperText}
                />
            </CardContent>
        </Card>
    );
};

export default AttributeEditor;