import React, { useCallback } from 'react';
import ManagedPage from "../../../../../components/apps/ManagedPage";
import GymWorkoutDisplay from "../GymWorkoutDisplay";
import WorkoutStats from './WorkoutStats';
import ExpandableCard from "../../../../../components/list/ExpandableCard";

function GymLog() {
    const customFilter = (item, filterText) => {
        const content = item.content?.content;
        if (!content) return false;

        const searchableText = `${content.goal} ${content.activities.map(a => a.activity).join(' ')} ${content.sourceName}`.toLowerCase();
        return searchableText.includes(filterText.toLowerCase());
    };

    const customSort = (a, b) => {
        const dateA = new Date(a.content?.content?.date || '');
        const dateB = new Date(b.content?.content?.date || '');
        return dateB - dateA; // Sort in descending order (most recent first)
    };

    const WrappedWorkoutRecord = useCallback(({ item, ...props }) => {
        const workoutDate = item.content.content.date || 'Unknown Date';
        const workoutGoal = item.content.content.goal || 'Workout';
        const title = `${workoutGoal} - ${workoutDate}`;

        return (
            <ExpandableCard title={title} initiallyExpanded={false}>
                <GymWorkoutDisplay initialWorkout={item.content.content} sourceRecord={item} {...props} />
            </ExpandableCard>
        );
    }, []);

    return (
        <ManagedPage
            title="Gym Workouts"
            addItemsButtonText="Add Workout"
            systemCollectionName="Gym Workouts"
            sourceType={encodeURIComponent("Gym Workout")}
            StatsComponent={WorkoutStats}
            ItemDisplay={WrappedWorkoutRecord}
            showExtractor={true}
            extractorProps={{
                availableTypes: [{ id: "Gym Workout", label: "Gym Workout" }],
                fromQuery: '/source/search?',
                toQuery: '/source/search?',
                fromRootAssociation: "Gym Workouts",
                fromPossibleTypes: ["Source", "Gym Workout"],
                showTo: false,
                showContext: false,
                showType: false,
            }}
            managedListProps={{
                filterFunction: customFilter,
                sortFunction: customSort,
                allowDelete: true,
            }}
        />
    );
}

export default GymLog;